import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Удвоение',
    text: 'Дано число \\(a\\). Выведите число в два раза больше',
    input: 'Вещественное число \\(a\\)',
    output: 'Удвоенное число',
    note: null,
    examples: [
      {
        input: '1',
        output: '2'
      },
      {
        input: '15.5',
        output: '31.0'
      },
      {
        input: '-7.25',
        output: '-14.5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Половина',
    text: 'Дано число \\(a\\). Выведите число в два раза меньше',
    input: 'Вещественное число \\(a\\)',
    output: 'Половина числа',
    note: null,
    examples: [
      {
        input: '2',
        output: '1.0'
      },
      {
        input: '15.5',
        output: '7.75'
      },
      {
        input: '-6',
        output: '-3.0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение и половина',
    text: 'Дано число \\(a\\). Выведите в первой строке число в два раза больше, а во второй - в два раза меньше',
    input: 'Вещественное число \\(a\\)',
    output: 'Удвоенное число, затем половина числа на другой строке',
    note: null,
    examples: [
      {
        input: '2',
        output: '4\n1.0'
      },
      {
        input: '15.5',
        output: '31.0\n7.75'
      },
      {
        input: '-6',
        output: '-12\n-3.0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма и разность',
    text: 'Даны два числа \\(a\\) и \\(b\\). Выведите через пробел их сумму и разность',
    input: 'Вещественные числа \\(a\\) и \\(b\\)',
    output: 'Сумма и разность через пробел',
    note: null,
    examples: [
      {
        input: '5, 3',
        output: '8 2'
      },
      {
        input: '15.5, 0.5',
        output: '16.0 15.0'
      },
      {
        input: '7, 7.5',
        output: '14.5 -0.5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Квадраты',
    text: 'Даны два числа \\(a\\) и \\(b\\). Выведите в первой строке их квадраты через пробел, а во второй строке квадрат их суммы',
    input: 'Вещественные числа \\(a\\) и \\(b\\)',
    output: 'Квадраты чисел и квадрат их суммы',
    note: null,
    examples: [
      {
        input: '1, 1',
        output: '1 1\n4'
      },
      {
        input: '5, 3.5',
        output: '25 12.25\n72.25'
      },
      {
        input: '-2, 2.5',
        output: '4 6.25\n0.25'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма покупки',
    text: 'В магазине купили \\(k_1\\) штук первого товара по цене \\(p_1\\) за штуку и \\(k_2\\) второго по \\(p_2\\) за штуку. Выведите сумму покупки',
    input: '\\(k_1\\), \\(p_1\\), \\(k_2\\), \\(p_2\\)<br>Целые положительные \\(k_1\\) и \\(k_2\\), целые или вещественные положительные числа \\(p_1\\) и \\(p_2\\)',
    output: 'Сумма покупки',
    note: null,
    examples: [
      {
        input: '1, 10, 1, 20',
        output: '30'
      },
      {
        input: '5, 100, 10, 4.5',
        output: '545.0'
      },
      {
        input: '2, 1.5, 5, 1.5',
        output: '10.5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество чисел в диапазоне включительно',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a < b\\)). Сколько чисел в диапазоне от \\(a\\) до \\(b\\) включительно?',
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Количество чисел',
    note: null,
    examples: [
      {
        input: '-1, 1',
        output: '3'
      },
      {
        input: '0, 3',
        output: '4'
      },
      {
        input: '1, 10',
        output: '10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество чисел в диапазоне невключительно',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a < b\\)). Сколько чисел в диапазоне от \\(a\\) до \\(b\\) не включая сами \\(a\\) и \\(b\\)?',
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Количество чисел',
    note: null,
    examples: [
      {
        input: '-1, 1',
        output: '1'
      },
      {
        input: '0, 3',
        output: '2'
      },
      {
        input: '1, 10',
        output: '8'
      }
    ]
  },
  {
    component: Simple,
    name: '0 и 1',
    text: 'Дано число \\(a\\), равное либо 0, либо 1. Выведите 0, если \\(a = 1\\), и 1, если \\(a = 0\\)',
    input: '\\(a\\) равно либо 0, либо 1',
    output: '0 или 1',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '0',
        output: '1'
      },
      {
        input: '1',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: '1 и 2',
    text: 'Дано число \\(a\\), равное либо 1, либо 2. Выведите 1, если \\(a = 2\\), и 2, если \\(a = 1\\)',
    input: '\\(a\\) равно либо 1, либо 2',
    output: '1 или 2',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '1',
        output: '2'
      },
      {
        input: '2',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: '4 и 7',
    text: 'Дано число \\(a\\), равное либо 4, либо 7. Выведите 4, если \\(a = 7\\), и 7, если \\(a = 4\\)',
    input: '\\(a\\) равно либо 4, либо 7',
    output: '4 или 7',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '4',
        output: '7'
      },
      {
        input: '7',
        output: '4'
      }
    ]
  },
  {
    component: Simple,
    name: '-3 и 7',
    text: 'Дано число \\(a\\), равное либо -3, либо 7. Выведите -3, если \\(a = 7\\), и 7, если \\(a = -3\\)',
    input: '\\(a\\) равно либо -3, либо 7',
    output: '-3 или 7',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '-3',
        output: '7'
      },
      {
        input: '7',
        output: '-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Третий лишний',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Число \\(c\\) совпадает либо с \\(a\\), либо с \\(b\\). Выведите число, с которым не совпадает \\(c\\)',
    input: '\\(a\\), \\(b\\), \\(c\\) - вещественные числа',
    output: 'Число, с которым не совпадает \\(c\\)',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '1, 3, 3',
        output: '1'
      },
      {
        input: '10, 20, 10',
        output: '20'
      },
      {
        input: '-4.5, -5.5, -5.5',
        output: '-4.5'
      }
    ]
  },
  {
    component: Simple,
    name: '1 2 3 - 3 2 1',
    text: 'Дано число \\(a\\), равное либо 1, либо 2, либо 3. Выведите 3, 2 или 1 соответственно',
    input: '\\(a\\) равно либо 1, либо 2, либо 3',
    output: '1, 2 или 3',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '1',
        output: '3'
      },
      {
        input: '2',
        output: '2'
      },
      {
        input: '3',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: '2 4 6 - 6 4 2',
    text: 'Дано число \\(a\\), равное либо 2, либо 4, либо 6. Выведите 6, 4 или 2 соответственно',
    input: '\\(a\\) равно либо 2, либо 4, либо 6',
    output: '2, 4 или 6',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '2',
        output: '6'
      },
      {
        input: '4',
        output: '4'
      },
      {
        input: '6',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: '1 2 3 - 9 6 3',
    text: 'Дано число \\(a\\), равное либо 1, либо 2, либо 3. Выведите 9, 6 или 3 соответственно',
    input: '\\(a\\) равно либо 1, либо 2, либо 3',
    output: '3, 6 или 9',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '1',
        output: '9'
      },
      {
        input: '2',
        output: '6'
      },
      {
        input: '3',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: '1 2 3 - 30 20 10',
    text: 'Дано число \\(a\\), равное либо 1, либо 2, либо 3. Выведите 30, 20 или 10 соответственно',
    input: '\\(a\\) равно либо 1, либо 2, либо 3',
    output: '10, 20 или 30',
    note: 'Можно использовать только арифметику',
    examples: [
      {
        input: '1',
        output: '30'
      },
      {
        input: '2',
        output: '20'
      },
      {
        input: '3',
        output: '10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тяжёлое видео',
    text: 'По сети передаётся некоторое видео c разрешением \\(w\\) * \\(h\\) пикселей (\\(w\\) - ширина, \\(h\\) - высота). Если увеличить ширину на \\(x\\) пикселей, а высоту увеличить на \\(y\\) пикселей, то во сколько раз увеличится нагрузка на сеть?',
    input: '\\(w\\), \\(h\\), \\(x\\), \\(y\\) - целые положительные числа',
    output: 'Во сколько раз увеличится нагрузка на сеть',
    note: null,
    examples: [
      {
        input: '1280, 720, 640, 360',
        output: '2.25'
      },
      {
        input: '640, 480, 640, 240',
        output: '3.0'
      },
      {
        input: '960, 540, 960, 540',
        output: '4.0'
      }
    ]
  },
  {
    component: Simple,
    name: 'N-ный член арифметической прогрессии',
    text: 'Предположим, что есть последовательность чисел, начинающаяся от числа \\(a\\), каждое следующее число на \\(d\\) больше предыдущего. Какое число будет стоять в последовательности под номером \\(n\\), если \\(a\\) считать первым?',
    input: '\\(a\\), \\(d\\) - целые, \\(n\\) - целое положительное',
    output: 'Число под номером \\(n\\)',
    note: null,
    examples: [
      {
        input: '100, 10, 3',
        output: '120'
      },
      {
        input: '1, 1, 5',
        output: '5'
      },
      {
        input: '-100, 50, 6',
        output: '150'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма арифметической прогрессии',
    text: 'Предположим, что есть последовательность чисел, начинающаяся от числа \\(a\\), каждое следующее число на \\(d\\) больше предыдущего. Какова сумма первых \\(n\\) чисел из последовательности?',
    note: 'Можно воспользоваться формулой суммы арифметической прогрессии: \\(S = \\frac{a_1+a_n}{2} \\cdot n\\), где \\(a_1\\) - первый член прогрессии, \\(a_n\\) - последний, \\(n\\) - количество чисел',
    input: '\\(a\\), \\(d\\) - целые, \\(n\\) - целое положительное',
    output: 'Сумма первых \\(n\\) чисел',
    examples: [
      {
        input: '0, 5, 3',
        output: '15.0'
      },
      {
        input: '10, 10, 4',
        output: '100.0'
      },
      {
        input: '-100, 50, 5',
        output: '0.0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Квадратный корень',
    text: 'Даны числа \\(a\\) и \\(b\\). Выведите на первой строке их квадратные корни, а на второй квадратный корень из их суммы',
    input: '\\(a\\), \\(b\\) - вещественные неотрицательные числа',
    output: '\\(\\sqrt{a}\\), \\(\\sqrt{b}\\) в первой строке,  \\(\\sqrt{a + b}\\) во второй',
    note: 'Для извлечения квадратного корня можно воспользоваться возведением в степень 0.5',
    examples: [
      {
        input: '9, 16',
        output: '3.0 4.0\n5.0'
      },
      {
        input: '0, 4',
        output: '0.0 2.0\n2.0'
      },
      {
        input: '8.0, 2.25',
        output: '2.8284271247461903 1.5\n3.2015621187164243'
      }
    ]
  },
  {
    component: Simple,
    name: 'Размер картинки',
    text: 'О некоторой картинке известно соотношение сторон (пропорция ширины к высоте): \\(w\\) к \\(h\\), а также общее количество пикселей \\(p\\). Выведите ширину картинки в пикселях',
    input: '\\(w\\), \\(h\\), \\(p\\) - целые положительные числа',
    output: 'Ширина картинки в пикселях',
    note: null,
    examples: [
      {
        input: '4, 3, 120000',
        output: '400.0'
      },
      {
        input: '16, 9, 14400',
        output: '160.0'
      },
      {
        input: '20, 9, 288000',
        output: '800.0'
      }
    ]
  }
]
